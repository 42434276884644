<template>

<v-row>
    <v-col
        cols="12"
        md="12">
        <v-row>
            <v-col cols="12">
                <dashboard-boletos 
                v-if="this.listaBolCarregou" 
                :boletos="this.boletosreimpressao"
                :boletosNaoVencidos="this.boletosNaoVencidos"></dashboard-boletos>
            </v-col>
        </v-row>
    </v-col>
</v-row>

</template>

<script>

import DashboardBoletos from './../portal/DashboardBoletos.vue'
import Cookie from 'js-cookie'
import UsuarioStore from './../../store/Usuario'
import Strings from '@/common/strings'
import moment from 'moment'

export default {
    components: {
    DashboardBoletos,
  },
  mounted: function(){
      this.$loading(true)
      UsuarioStore.boletosVencidos(Strings.imobiliariaId).then(result => {
        
            result.data.forEach((v, idx) =>{

                let tipStatus = 1
                if(moment(v.dataVencimento).isBefore()){
                    tipStatus = 2
                }
                var fBoletos = {
                    index: idx,
                    responsive_id: v.nossoNumero,
                    id: v.nossoNumero,
                    vencimento: moment(String(v.dataVencimento)).format('DD/MM/YYYY'),
                    obra: v.descricaoObra,
                    empreendimento: v.descricaoEmpresa,
                    identificador: '',
                    valor: parseFloat(v.valorDocumento).toFixed(2),
                    status: tipStatus,
                    acoes: v,
                  }
                  if(moment(v.dataVencimento).isAfter()){
                    this.boletosNaoVencidos.push(fBoletos)
                  }
                  this.boletosreimpressao.push(fBoletos)
                })

            this.listaBolCarregou = true
        }).catch(error => {
            console.log(error)
            if(typeof error.response != 'undefined' && error.response.status === 401){
                Cookie.remove(Strings.cookie_token)
                Cookie.remove(Strings.cookie_username)
                this.$router.push('/pages/login')
            }
            
            
        }).finally(()=>{
            this.$loading(false)
        })
  },
  setup(){
      const boletosreimpressao = []
      const boletosNaoVencidos = []
      const listaBolCarregou = false

      return {
      boletosreimpressao,
      boletosNaoVencidos,
      listaBolCarregou
    }
  },
}
</script>
